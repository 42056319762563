@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');

html, body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Pretendard Variable', -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  z-index: -10;
  -webkit-text-size-adjust: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 1px rgba(0,0,0,0.2);
  background-color: #ffffff;
}

body {
  overflow-y: scroll;
}

input, textarea, button {
  font-family: 'Pretendard Variable', 'Noto Sans KR', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;
}

* {
  box-sizing: border-box;
}

ol, ul {
  padding-left: 16px;
}

svg {
  font-family: 'Pretendard Variable', 'Noto Sans KR', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

body {
  font-size: 14px;
}

h1, h2 {
  font-weight: 500;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.1em;
}

p {
  font-size: 1em;
  line-height: 1.7;
}

.flex {
  display: flex;
}

.f-h-between {
  justify-content: space-between;
}

.f-h-end {
  justify-content: flex-end;
}

.f-h-center {
  justify-content: center;
}

.f-v-center {
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-d-column {
  flex-direction: column;
}